.navBar {
  display: flex;
  justify-content: space-between;
  font-size: 1.4em;
  max-width: 1250px;
  margin: 0 auto 50px auto;
}

.navBarInner {
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
}

.navBarInner div {
  margin: 10px;
}

.navBarInner a {
  text-decoration: none;
  color: #0d6e98;
}

.navBarInner a:hover {
  color: #82981a;
}

.navBarLeft {
  padding-left: 30px;
}

.navBarRight {
  padding-right: 30px;
}

@media only screen and (max-width: 1070px) {
  .navBarInner {
    flex-direction: column;
    justify-content: center;
  }

  .navBar {
    font-size: 1em;
  }

  .navBarRight {
    padding-right: 10px;
  }

  .navBarLeft {
    padding-left: 10px;
  }
}
