.dailyMain p {
  font-size: 1.5em;
  width: 50vw;
  margin: auto;
  text-align: center;
  padding: 10px;
}

@media only screen and (max-width: 1070px) {
  .dailyMain p {
    width: 80vw;
  }
}