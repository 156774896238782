.contactMain {
  padding-top: 22px;
  font-size: large;
  display: flex;
  width: 70vw;
  justify-content: space-around;
  margin: auto;
}

.contactImageBox {
  height: 12em;
  width: 18em;
}

.contactImage {
  width: 100%;
  height: 100%;
}

.contactTextBox {
  width: 30em;
}

/* ------------------- Form --------------------- */

.contactFormMain {
  padding-top: 50px;
  font-size: large;
  display: flex;
  width: 70vw;
  justify-content: space-around;
  margin: auto;
}

.contactInfo {
  width: 13em;
}

.contactForm {
  width: 30em;
}

.contactForm h1 {
  margin-top: -10px;
}

.inputField {
  width: 200px;
}

.inputArea {
  margin-top: 2px;
  width: 200px;
  height: 100px;
}

.backendLink {
  text-decoration: none;
  color: black;
}

.backendLink:hover {
  cursor: initial;
}

@media only screen and (max-width: 1070px) {
  .contactMain {
    justify-content: space-between;
    flex-direction: column;
  }

  .contactTextBox {
    width: auto;
  }

  .contactFormMain {
    flex-direction: column;
  }

  .contactForm {
    margin-top: 30px;
  }
}