.newsMain p {
  font-size: 1.5em;
  width: 50vw;
  margin: auto;
  text-align: center;
  padding: 10px;
}

.logoLink {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 12em;
  width: 12em;
  margin: auto;
  padding-top: 10px;
}

.logoImage {
  max-width: 100%;
  max-height: 100%;
  height: auto;
}

.certPdf {
  padding-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 30em;
  width: auto;
}

@media only screen and (max-width: 1070px) {
  .newsMain p {
    width: 80vw;
  }
}